import axios from 'axios';

//const apiUri = "http://localhost:5000/";
const apiUri = "https://messaging.gadero.nl/";

export const SendSms = (transportlist, template) => {
    return axios.post(apiUri + `sms`, {
        TransportListId : transportlist,
        SmsTemplate : template
    }).then(x => x.data);    
}